import { DatePipe } from '@angular/common';
import {
  computed,
  inject,
  output,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';

import metadata from '../../assets/metadata.json';
import { fromLocalStorage } from '../common/signals/from-local-storage';
import {
  LoggerFactory,
  MDB_LOGGER_FACTORY,
} from '../core/observability/provider';
import {
  AuthStateService,
  AuthStatusType,
} from '../services/auth/auth-state.service';
import { IdleService } from '../services/idle/idle.service';

@Component({
  selector: 'app-dev-overlay',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe],
  templateUrl: './dev-overlay.component.html',
  styleUrl: './dev-overlay.component.scss',
})
export class DevOverlayComponent implements AfterViewInit {
  private readonly logger = inject<LoggerFactory>(MDB_LOGGER_FACTORY)(
    'DevOverlayComponent'
  );

  private readonly STORAGE_KEY = 'mdb_dev-panel-is-visible';

  public logout = output<void>();
  public validate = output<void>();

  private authStateServcie = inject(AuthStateService);
  private idleService = inject(IdleService);

  protected authStatus = computed(() => {
    return this.authStateServcie.select('status')();
  });

  protected isAuthenticated = computed(() => {
    const status = this.authStateServcie.select('status')();

    return status === AuthStatusType.Authenticated;
  });

  protected lastUpdateDate = computed(() => {
    return this.authStateServcie.select('lastUpdateDate')();
  });

  protected lastValidationDate = computed(() => {
    return this.authStateServcie.select('lastValidationDate')();
  });

  protected isIdle = computed(() => {
    return this.idleService.select('isIdle')();
  });

  protected lastActivityDate = computed(() => {
    return this.idleService.select('lastActivityDate')();
  });

  protected isOpen = fromLocalStorage<boolean>(this.STORAGE_KEY, false);

  protected validateRunning = false;
  protected validateRunningDate!: Date;

  protected buildInfo = {
    commit: metadata.hash,
    commitUrl: `https://github.com/Zotec-Product-Development/Web.MyDocBill/commit/${metadata.hash}`,
    version: metadata.releaseVersion,
  };

  private intervalId: ReturnType<typeof setInterval> | undefined;

  @ViewChild('details')
  private details!: ElementRef<HTMLDialogElement>;

  ngAfterViewInit() {
    this.details.nativeElement.open = this.isOpen();
  }

  onPanelToggle(event: Event) {
    const toggleEvent = event as ToggleEvent;

    this.isOpen.set(toggleEvent.newState === 'open');
  }

  onLogoutClick() {
    this.logout.emit();
  }

  onToggleValidationClick() {
    this.validateRunningDate = new Date();

    const validate = () => {
      this.validate.emit();
      this.logger.debug('User requested token validation');
    };

    // Stop running the code after 1 hour (60 minutes)
    setTimeout(
      () => {
        clearInterval(this.intervalId);
        this.logger.debug('Cleared: auto-validate timeout');
      },
      60 * 60 * 1000
    ); // Stop after 1 hour (3600000 milliseconds)

    if (!this.validateRunning) {
      this.intervalId = setInterval(validate, 60 * 1000); // Set interval for every 1 minute (60000 milliseconds)
      this.logger.debug('Started: auto-validate');
      this.validateRunning = true;
    } else {
      clearInterval(this.intervalId);
      this.logger.debug('Cleared: auto-validate');
      this.validateRunning = false;
    }
  }
}
