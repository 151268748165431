import {
  effect,
  inject,
  signal,
  untracked,
  WritableSignal,
} from '@angular/core';

import { MDB_LOGGER_FACTORY } from '../../core/observability/provider';

export const fromLocalStorage = <TValue>(
  key: string,
  defaultValue: TValue
): WritableSignal<TValue> => {
  const logger = inject(MDB_LOGGER_FACTORY)('fromLocalStorage');

  const storedValue = localStorage.getItem(key);

  let initialValue = defaultValue;

  if (storedValue) {
    try {
      initialValue = JSON.parse(storedValue) as TValue;
    } catch (_: unknown) {
      logger.warn('Failed to parse stored value.');
    }
  }

  const internalSignal = signal<TValue>(initialValue as TValue);

  effect(() => {
    const value = internalSignal();
    untracked(() => localStorage.setItem(key, String(value)));
  });

  return internalSignal;
};
