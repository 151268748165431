import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha-2';

import { environment } from '../environments/environment';

import { buildRouterConfiguration } from './app.routes';
import { INTERCEPTORS } from './core/interceptors/interceptors';
import { Logger } from './core/observability/logger';
import { provideObservability } from './core/observability/provider';
import { BrowserService } from './services/browser/browser.service';
import {
  provideAppBaseHref,
  VirtualSiteService,
} from './services/virtual-site/virtual-site.service';

const STATIC_PROVIDERS = [
  provideHttpClient(withInterceptors(INTERCEPTORS)),
  buildRouterConfiguration(),
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.recaptcha_site_key,
    } as RecaptchaSettings,
  },
  {
    provide: VirtualSiteService,
    useFactory: VirtualSiteService.factory,
  },
];

async function buildConfiguration(): Promise<ApplicationConfig> {
  const loggerFactory = (name: string) => new Logger(name);

  return {
    providers: [
      ...STATIC_PROVIDERS,
      provideObservability(),
      await provideAppBaseHref(
        loggerFactory,
        new BrowserService(loggerFactory, window)
      ),
    ],
  } as ApplicationConfig;
}

export const AppConfig = {
  buildConfiguration: buildConfiguration,
};
