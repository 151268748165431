import { A11yModule } from '@angular/cdk/a11y';
import { HttpResponse } from '@angular/common/http';
import {
  inject,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subscription, pairwise, startWith } from 'rxjs';

import {
  LoggerFactory,
  MDB_LOGGER_FACTORY,
} from '../core/observability/provider';
import {
  PaperlessBillingService,
  cityStateResponse,
  paperlessBillingEnrollmentResponse,
  paperlessBillingResponse,
} from '../services/paperless-billing/paperless-billing.service';
import { UspsService, uspsResponse } from '../services/usps/usps.service';
import { MDB_VIRTUAL_SITE } from '../services/virtual-site/virtual-site.service';
import { SvgComponent } from '../svg/svg.component';

export interface AddressInfo {
  address1: string | undefined | null;
  address2: string | undefined | null;
  zip: string | undefined | null;
  city: string | undefined | null;
  state: string | undefined | null;
  addressIndicator: string | undefined | null;
}

@Component({
  selector: 'app-paperless-billing-modal',
  imports: [A11yModule, ReactiveFormsModule, SvgComponent],
  templateUrl: './paperless-billing-modal.component.html',
  styleUrl: './paperless-billing-modal.component.scss',
})
export class PaperlessBillingModalComponent implements OnInit {
  private readonly logger = inject<LoggerFactory>(MDB_LOGGER_FACTORY)(
    'PaperlessBillingModalComponent'
  );

  private fb = inject(FormBuilder);

  private modalSubscription!: Subscription;
  private clientSettingsSubscription!: Subscription;

  private modalScreen = new BehaviorSubject<string>('');
  modalScreen$ = this.modalScreen.asObservable();
  private modalScreenSubscription!: Subscription;

  site = inject(MDB_VIRTUAL_SITE);
  uspsService = inject(UspsService);

  termsOfUseUrl =
    this.site.settings.links.termsOfUse ??
    'https://dcsokk5etzbbl.cloudfront.net/Pxp/terms-of-use.html';

  emailForm!: FormGroup | null;
  textForm!: FormGroup | null;
  paperForm!: FormGroup | null;

  paperlessBillingService = inject(PaperlessBillingService);
  type = '';
  inputPhoneNumber: string | undefined | null;
  inputEmail: string | undefined | null;
  inputAddress: AddressInfo = {
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    addressIndicator: '',
  };

  uspsAddress: AddressInfo = {
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    addressIndicator: '',
  };

  formErrors = {
    badEmail:
      'The email you have entered cannot receive emails properly. Please use another email.',
    badMobile:
      'The number you have entered cannot accept text messages. Please use another number.',
  };

  isEnteredAddress: boolean = false;
  isUspsAddress: boolean = false;
  completeUspsModal: boolean = false;
  isBadEmail: boolean = false;
  validatedBadEmail: boolean = false;
  isBadMobile: boolean = false;
  validatedBadMobile: boolean = false;

  @ViewChild('billing') billingDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('successDialog') successDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('uspsDialog') uspsDialog!: ElementRef<HTMLDialogElement>;

  showBillingModal() {
    if (this.billingDialog) {
      if (
        this.paperlessBillingService.getPaperlessBillingInfo()
          .emailIndicator === 'B'
      ) {
        this.isBadEmail = true;
      }
      if (
        this.paperlessBillingService.getPaperlessBillingInfo()
          .mobileIndicator === 'B'
      ) {
        this.isBadMobile = true;
      }
      this.billingDialog.nativeElement.showModal();
    }
  }

  closeBillingModal() {
    if (this.billingDialog) {
      this.billingDialog.nativeElement.close();
      this.isBadEmail = false;
      this.isBadMobile = false;
    }
  }

  showSuccessModal() {
    this.successDialog.nativeElement.showModal();
  }

  closeSuccessModal() {
    this.successDialog.nativeElement.close();
    this.isBadEmail = false;
    this.isBadMobile = false;
    this.paperlessBillingService.fetchPaperlessBillingInfo();
  }

  showUspsModal() {
    this.uspsDialog.nativeElement.showModal();
  }

  closeUspsModal() {
    this.uspsDialog.nativeElement.close();
  }

  cancelUspsModal() {
    this.paperForm?.get('verifyAddress')?.setValue(false);
    this.completeUspsModal = true;
    this.uspsDialog.nativeElement.close();
  }

  radioSelectText() {
    this.modalScreen.next('text');
  }

  radioSelectEmail() {
    this.modalScreen.next('email');
  }

  radioSelectEnteredAddress() {
    this.isEnteredAddress = true;
    this.isUspsAddress = false;
  }

  radioSelectUspsAddress() {
    this.isEnteredAddress = false;
    this.isUspsAddress = true;
  }

  selectPaperless() {
    this.paperlessBillingService.response$.subscribe({
      next: (emailSettings: paperlessBillingResponse | null) => {
        if (emailSettings && emailSettings.isEnabled) {
          this.paperlessSelection(emailSettings);
        }
      },
    });
  }

  selectPaper() {
    this.modalScreen.next('paper');
  }

  ngOnInit(): void {
    this.paperlessBillingService.fetchPaperlessBillingInfo();
    this.paperlessBillingService.response$.subscribe({
      next: (emailSettings: paperlessBillingResponse | null) => {
        if (emailSettings && emailSettings.isEnabled) {
          this.paperlessSelection(emailSettings);
        } else {
          this.modalScreen.next('paper');
        }

        if (
          this.paperlessBillingService.getPaperlessBillingInfo()
            .enrollmentState === 'NOT ENROLLED' ||
          this.paperlessBillingService.getPaperlessBillingInfo()
            .enrollmentState === 'DISCOVERED' ||
          this.paperlessBillingService.getPaperlessBillingInfo()
            .enrollmentState === '' ||
          (this.paperlessBillingService.getPaperlessBillingInfo()
            .emailIndicator === 'B' &&
            this.paperlessBillingService.getPaperlessBillingInfo()
              .communicationPreference === 'EML') || //BAD EMAIL Indication, regardless of Login path
          (this.paperlessBillingService.getPaperlessBillingInfo()
            .mobileIndicator === 'B' &&
            this.paperlessBillingService.getPaperlessBillingInfo()
              .communicationPreference === 'SMS')
        ) {
          this.showBillingModal();
        }
      },
    });

    this.modalSubscription = this.paperlessBillingService.modalState$.subscribe(
      () => {
        this.showBillingModal();
      }
    );

    this.modalScreenSubscription = this.modalScreen$.subscribe(
      (res: string) => {
        this.type = res;
        if (res === 'email') {
          this.emailForm = this.fb.group({
            emailAddress: [
              this.paperlessBillingService.getPaperlessBillingInfo()
                .currentEmail,
              [Validators.required, Validators.email, this.validateBadEmail()],
            ],
            verifyEmail: ['', [Validators.required]],
          });
          if (
            this.paperlessBillingService.getPaperlessBillingInfo()
              .emailIndicator === 'B'
          ) {
            this.isBadEmail = true;
          }
          if (
            this.paperlessBillingService.getPaperlessBillingInfo()
              .mobileIndicator === 'B'
          ) {
            this.isBadMobile = true;
          }
        } else if (res === 'text') {
          this.textForm = this.fb.group({
            phoneNumber: [
              this.paperlessBillingService.getPaperlessBillingInfo()
                .currentMobile !== null
                ? this.formatPhone(
                    this.paperlessBillingService.getPaperlessBillingInfo()
                      .currentMobile
                  )
                : '',
              [
                Validators.required,
                Validators.pattern('^([0-9]{3}-[0-9]{3}-[0-9]{4})$'),
                this.validateBadMobile(),
              ],
            ],
            verifyPhone: ['', [Validators.required]],
          });
          if (
            this.paperlessBillingService.getPaperlessBillingInfo()
              .emailIndicator === 'B'
          ) {
            this.isBadEmail = true;
          }
          if (
            this.paperlessBillingService.getPaperlessBillingInfo()
              .mobileIndicator === 'B'
          ) {
            this.isBadMobile = true;
          }
        } else {
          this.paperForm = this.fb.group({
            address1: [
              this.paperlessBillingService.getPaperlessBillingInfo()
                .streetAddress1,
              [Validators.required],
            ],
            address2: [
              this.paperlessBillingService.getPaperlessBillingInfo()
                .streetAddress2,
            ],
            zip: [
              this.paperlessBillingService.getPaperlessBillingInfo().zip,
              [Validators.required, Validators.pattern('[0-9]{5}$')],
            ],
            city: [
              this.paperlessBillingService.getPaperlessBillingInfo().city,
              [Validators.required],
            ],
            state: [
              this.paperlessBillingService.getPaperlessBillingInfo().state,
              [Validators.required],
            ],
            verifyAddress: [false, [Validators.required]],
          });
          this.paperForm.get('verifyAddress')?.valueChanges.subscribe(() => {
            this.completeUspsModal = false;
            this.verifyAddress();
          });

          this.paperForm.valueChanges
            .pipe(
              startWith(this.paperForm.value), // To provide an initial value for comparison
              pairwise() // Emits previous and current values as a pair
            )
            .subscribe(([prevValue, currValue]) => {
              const changedField = this.getChangedField(prevValue, currValue);
              if (changedField !== undefined) {
                if (changedField !== 'verifyAddress') {
                  this.paperForm?.get('verifyAddress')?.setValue(false);
                }
              }
            });
        }
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getChangedField(prevValue: any, currValue: any): string | undefined {
    for (const key in currValue) {
      if (prevValue[key] !== currValue[key]) {
        return key; // Return the name of the changed control
      }
    }
    return undefined;
  }

  paperlessSelection(emailSettings: paperlessBillingResponse) {
    if (
      emailSettings.communicationPreference === 'EML' &&
      emailSettings.emailSettings.offerEmail
    ) {
      this.modalScreen.next('email');
    } else if (
      emailSettings.communicationPreference === 'SMS' &&
      emailSettings.emailSettings.offerText
    ) {
      this.modalScreen.next('text');
    } else if (emailSettings.emailSettings.offerText) {
      this.modalScreen.next('text');
    } else if (emailSettings.emailSettings.offerEmail) {
      this.modalScreen.next('email');
    }
  }

  /* istanbul ignore next Services should be tested within their own specific test files */
  emailSubmit() {
    if (this.emailForm) {
      this.paperlessBillingService
        .enrollWithEmail({ emailAddress: this.emailForm.value.emailAddress })
        .subscribe((res: HttpResponse<paperlessBillingEnrollmentResponse>) => {
          if (
            res.status === 200 &&
            res.body?.success === true &&
            this.emailForm
          ) {
            this.closeBillingModal();
            this.inputEmail = this.emailForm.value.emailAddress;
            this.emailForm.reset();
            this.isBadEmail = false;
            this.showSuccessModal();
          }
        });
    }
  }

  /* istanbul ignore next Services should be tested within their own specific test files */
  textSubmit() {
    if (this.textForm) {
      this.paperlessBillingService
        .enrollWithMobile({ phoneNumber: this.textForm.value.phoneNumber })
        .subscribe((res: HttpResponse<paperlessBillingEnrollmentResponse>) => {
          if (
            res.status === 200 &&
            res.body?.success === true &&
            this.textForm
          ) {
            this.closeBillingModal();
            this.inputPhoneNumber = this.textForm.value.phoneNumber;
            this.textForm.reset();
            this.isBadMobile = false;
            this.showSuccessModal();
          }
        });
    }
  }

  /* istanbul ignore next Services should be tested within their own specific test files */
  optOutSubmit() {
    if (this.paperForm) {
      this.paperlessBillingService
        .optOut({
          address1: this.paperForm.value.address1,
          address2: this.paperForm.value.address2,
          zip: this.paperForm.value.zip,
          city: this.paperForm.value.city,
          state: this.paperForm.value.state,
        })
        .subscribe({
          next: (res: HttpResponse<paperlessBillingEnrollmentResponse>) => {
            if (
              res.status === 200 &&
              res.body?.success === true &&
              this.paperForm
            ) {
              this.closeBillingModal();
              this.inputAddress.address1 = this.paperForm.value.address1;
              this.inputAddress.address2 = this.paperForm.value.address2;
              this.inputAddress.zip = this.paperForm.value.zip;
              this.inputAddress.city = this.paperForm.value.city;
              this.inputAddress.state = this.paperForm.value.state;
              this.paperForm.reset();
              this.showSuccessModal();
            }
          },
          error: (error: unknown) => {
            this.logger.error('Failed to opt-out', undefined, error);
          },
        });
    }
  }

  /* istanbul ignore next Services should be tested within their own specific test files */
  verifyAddress() {
    let hasVerifyAddressChecked = false;
    if (this.paperForm?.get('verifyAddress')?.value === true) {
      hasVerifyAddressChecked = true;
    }
    if (this.paperForm && hasVerifyAddressChecked) {
      this.uspsService
        .validateAddress({
          address1: this.paperForm.value.address1,
          address2: this.paperForm.value.address2,
          zip: this.paperForm.value.zip,
          city: this.paperForm.value.city,
          state: this.paperForm.value.state,
        })
        .subscribe({
          next: (res: HttpResponse<uspsResponse>) => {
            if (
              res.status === 200 &&
              res.body?.isSuccess === true &&
              this.paperForm
            ) {
              this.inputAddress.address1 = this.paperForm.value.address1;
              this.inputAddress.address2 = this.paperForm.value.address2;
              this.inputAddress.zip = this.paperForm.value.zip;
              this.inputAddress.city = this.paperForm.value.city;
              this.inputAddress.state = this.paperForm.value.state;

              this.uspsAddress.address1 =
                res.body.addressInfo.address.streetAddress;
              this.uspsAddress.address2 =
                res.body.addressInfo.address.secondaryAddress;
              this.uspsAddress.zip = res.body.addressInfo.address.zipCode;
              this.uspsAddress.city = res.body.addressInfo.address.city;
              this.uspsAddress.state = res.body.addressInfo.address.state;

              this.isEnteredAddress = true;
              this.showUspsModal();
            } else if (
              res.status === 200 &&
              res.body?.isSuccess === false &&
              this.paperForm
            ) {
              this.inputAddress.address1 = this.paperForm.value.address1;
              this.inputAddress.address2 = this.paperForm.value.address2;
              this.inputAddress.zip = this.paperForm.value.zip;
              this.inputAddress.city = this.paperForm.value.city;
              this.inputAddress.state = this.paperForm.value.state;

              this.completeUspsModal = true;
            }
          },
          error: (error: unknown) => {
            this.logger.error('Failed to validate address', undefined, error);
          },
        });
    }
  }

  useVerifiedAddress() {
    this.paperForm?.reset();

    if (this.isEnteredAddress) {
      this.paperForm?.patchValue(
        {
          address1: this.inputAddress.address1,
          address2:
            this.uspsAddress.address2 !== null ? this.uspsAddress.address2 : '',
          zip: this.inputAddress.zip,
          city: this.inputAddress.city,
          state: this.inputAddress.state,
          verifyAddress: true,
        },
        { emitEvent: false }
      );

      this.completeUspsModal = true;
      this.closeUspsModal();
    } else if (this.isUspsAddress) {
      this.paperForm?.patchValue(
        {
          address1: this.uspsAddress.address1,
          address2:
            this.uspsAddress.address2 !== null ? this.uspsAddress.address2 : '',
          zip: this.uspsAddress.zip,
          city: this.uspsAddress.city,
          state: this.uspsAddress.state,
          verifyAddress: true,
        },
        { emitEvent: false }
      );

      this.completeUspsModal = true;
      this.closeUspsModal();
    } else {
      this.paperForm?.reset();
      this.closeUspsModal();
    }
  }

  /* istanbul ignore next Custom Validator Works and shows Error in test*/
  validateBadEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const enteredEmail = control.value;

      if (
        this.isBadEmail &&
        enteredEmail ===
          this.paperlessBillingService.getPaperlessBillingInfo().currentEmail
      ) {
        return { badEmail: true };
      } else {
        return null;
      }
    };
  }

  /* istanbul ignore next Custom Validator Works and shows Error in test*/
  checkBadEmail() {
    if (this.emailForm) {
      if (this.emailForm.get('emailAddress')?.getError('badEmail')) {
        this.validatedBadEmail = true;
      } else {
        this.validatedBadEmail = false;
      }
    }
  }

  /* istanbul ignore next Custom Validator Works and shows Error in test*/
  validateBadMobile(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const enteredPhoneNumber = control.value;

      if (
        this.isBadMobile &&
        enteredPhoneNumber ===
          this.formatPhone(
            this.paperlessBillingService.getPaperlessBillingInfo().currentMobile
          )
      ) {
        return { badMobile: true };
      } else {
        return null;
      }
    };
  }

  /* istanbul ignore next Custom Validator Works and shows Error in test*/
  checkBadMobile() {
    if (this.textForm) {
      if (this.textForm.get('phoneNumber')?.getError('badMobile')) {
        this.validatedBadMobile = true;
      } else {
        this.validatedBadMobile = false;
      }
    }
  }

  /* istanbul ignore next Services should be tested within their own specific test files */
  updateCityState() {
    if (
      this.paperForm &&
      this.paperForm.get('zip')?.value.length === 5 &&
      !this.paperForm.get('zip')?.errors
    ) {
      this.paperlessBillingService
        .fetchCityStateInfo({ zipCode: this.paperForm?.value.zip })
        .subscribe({
          next: (res: HttpResponse<cityStateResponse>) => {
            if (
              res.status === 200 &&
              res.body?.success === true &&
              this.paperForm
            ) {
              this.paperForm?.get('city')?.setValue(res.body.city);
              this.paperForm?.get('state')?.setValue(res.body.state);
            }
          },
          error: (error: unknown) => {
            this.logger.error(
              'Failed to fetch city and state',
              undefined,
              error
            );
          },
        });
    }
  }

  formatPhone(baseMobile: string): string {
    if (baseMobile.length >= 10) {
      const part1 = baseMobile.substring(0, 3);
      const part2 = baseMobile.substring(3, 6);
      const part3 = baseMobile.substring(6, 10);

      return part1 + '-' + part2 + '-' + part3;
    } else {
      return baseMobile;
    }
  }

  ngOnDestory() {
    this.modalSubscription.unsubscribe();

    this.modalScreenSubscription.unsubscribe();

    this.clientSettingsSubscription.unsubscribe();
  }
}
